/* eslint-disable @typescript-eslint/naming-convention */
import produce, { Draft } from 'immer'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import * as authAction from '../actions/auth'
import * as organizationAction from '../actions/organization'
import {
  ADD_AGENT,
  ADD_MANAGER,
  DELETE_AGENT,
  DELETE_MANAGER,
} from '../actions/organization'
import { HubtypeUser } from '../models/hubtype-user'
import { OAuth } from '../models/oauth'
import { JsonFor } from '../utils/json-utils'
import { State, initialState } from './auth.state'
export const jsonConverter: JsonConvert = new JsonConvert()
jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

export function deserialize(json: JsonFor<State>): State {
  return {
    is_logged_in: json.is_logged_in || false,
    oauth: json.oauth
      ? jsonConverter.deserializeObject(json.oauth, OAuth)
      : null,
    me: null,
    error: null, // no need to restore it
    pusherConnectionStablished: false, // we don't want to restore it because every time we refresh the page, we want to reconnect to pusher
  }
}

export function reducer(state?: State, action: any = {}) {
  return produce((draftState: Draft<State>, appraisalAction: any) => {
    switch (appraisalAction.type) {
      case authAction.LOGIN:
        draftState.error = null
        draftState.is_logged_in = true
        draftState.oauth = appraisalAction.payload
        return draftState
      case authAction.LOGOUT:
        return initialState
      case authAction.SET_ME:
        draftState.is_logged_in = true
        draftState.me = appraisalAction.payload
        return draftState
      case authAction.ERROR:
        draftState.error = appraisalAction.payload
        return draftState
      case organizationAction.UPDATE_USER:
        if (draftState.me && draftState.me.id === appraisalAction.payload.id) {
          draftState.me = jsonConverter.deserializeObject(
            { ...draftState.me, ...appraisalAction.payload },
            HubtypeUser
          )
        }
        return draftState
      case authAction.UPDATE_ME_STATUS:
        draftState.me.status = appraisalAction.payload
        return draftState
      case ADD_AGENT: //Add agent to queue
        if (
          draftState.me &&
          draftState.me.id === appraisalAction.payload.user.id &&
          !draftState.me.queues.find(
            queue => queue.id === appraisalAction.payload.queue.id
          )
        ) {
          draftState.me.queues = [
            ...draftState.me.queues,
            ...[
              {
                id: appraisalAction.payload.queue.id,
                name: appraisalAction.payload.queue.name,
              },
            ],
          ]
        }

        return draftState
      case DELETE_AGENT: //Delete agent from queue
        if (
          draftState.me &&
          draftState.me.id === appraisalAction.payload.user.id
        ) {
          draftState.me.queues = draftState.me.queues.filter(
            queue => queue.id !== appraisalAction.payload.queue.id
          )
        }
        return draftState
      case ADD_MANAGER: //Add manager to project
        if (
          draftState.me &&
          draftState.me.id === appraisalAction.payload.user.id
        ) {
          draftState.me.queues = [
            ...draftState.me.queues,
            ...appraisalAction.payload.project.queues,
          ]
        }
        return draftState
      case DELETE_MANAGER: //Delete manager from project
        if (
          draftState.me &&
          draftState.me.id === appraisalAction.payload.user.id
        ) {
          draftState.me.queues = draftState.me.queues.filter(
            queue =>
              !appraisalAction.payload.project.queues.find(
                queueProject => queueProject.id === queue.id
              )
          )
        }
        return draftState
      case authAction.SET_PUSHER_CONNECTION_STATUS:
        draftState.pusherConnectionStablished = appraisalAction.payload
        return draftState
      default:
        return draftState
    }
  }, initialState)(state, action)
}
